/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import '../../VodafoneIdea.scss'
import SectionHeader from '../../vilBranding/SectionHeader'
import './crf.css'
import '../../CafView/ItfsCaf/itfs.css'
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { useAuthContext } from '../../../../../../context/auth.context'
import { TERMS_VERSION, getVersionTerms, encode } from '../../cafCrf.util'
import { Spinner, Center, useTheme } from '@chakra-ui/react'
import { formatHHMMSS } from '../../../../../../utils/date.utils'

const CRF = ({ formData, queueData, romsActivity, submittedForms }) => {
  const [CrfData, setCrfData] = useState(false)
  const [nflCrfDataupdated, setUpdatedCrfData] = useState(null)
  const [companySeal, setCompanySeal] = useState(null)
  const [photoGraph, setPhotograph] = useState(null)
  const [authorizedSignatoryName, setAuthSignName] = useState(null)
  const [imageData, setImageData] = useState(null)
  const [imageType, setImageType] = useState(null)
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null)
  const [photoGraphImageData, setphotoGraphImageData] = useState(null)
  const [photoGraphImageType, setphotoGraphImageType] = useState(null)
  const [excelJson, setexcelJson] = useState(null)

  const { mutate } = useLoadRomsFormFieldListData()
  const { mutate: loadOrderFormValues } = useLoadFieldValues()
  const { mutate: loadExcelJsonValues } = useExcelJsonList()
  const { mutate: loadCompanySeal } = useLoadCompanySeal()

  let componentRef = React.useRef()
  const chakraTheme = useTheme()

  const {
    state: { authData },
  } = useAuthContext()

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data
          setCrfData(false)
          setUpdatedCrfData(finalFields)
        },
        onError: async err => {
          console.log('error occurred while loading fields', err)
        },
      }
    )
  }

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    )
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 12415
            )
            let excelUrl
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result))
                  },
                }
              )
            }
          }
        },
      }
    )
  }

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1756,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = ''
          let authorizedSignatoryName = ''
          let data = []
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted
          } else {
            data = []
          }
          data.forEach(function (fieldObj, index) {
            if (Number(fieldObj.field_id) === 19019) {
              companySeal = fieldObj.field_value
            }
            if (Number(fieldObj.field_id) === 19018) {
              authorizedSignatoryName = fieldObj.field_value
            }
          })
          setCompanySeal(companySeal)
          setAuthSignName(authorizedSignatoryName)
        },
      }
    )
  }

  useEffect(() => {
    setCrfData(true)
    fetchCompanySeal()
    fetchFieldListData()
    fetchOrderFormValues()
  }, [])

  useEffect(() => {
    let data = nflCrfDataupdated
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data)
          setImageType(type)
        })
      }
      if (!!data[18837].value) {
        // manager sign url
        let acctManagerSignUrl = data[18837].value
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data)
          setacctMngSignImageType(type)
        })
      }
    }
  }, [nflCrfDataupdated, companySeal, photoGraph, authorizedSignatoryName])

  let nflCrfContentView = ''

  if (!!nflCrfDataupdated) {
    let firstCaps = ''
    let secondBold = ''
    let Signcss
    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ')
      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
        secondBold = customerSignSplit['1']
        Signcss = 'signate_img input_sign'
      } else {
        firstCaps = customerSignSplit['0']
        secondBold = ''
        Signcss = 'signate_img input_sign under_line'
      }
    }

    nflCrfContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <SectionHeader name="PRI/TFS/ACS/VCS Change Request Form">
                  {' '}
                </SectionHeader>
                <div className="pad_20t">
                  <div className="over_hid">
                    <div className="pd_t5">
                      <div className="caf">
                        CAF ID
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18733].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid mar_t-5">
                    <div className="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span className="pad_l10">
                        <input
                          className="form_col_input"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18734].value}
                          readOnly
                        />
                      </span>
                      <div className="caf_inner">
                        <strong>Account Code</strong>
                        <input
                          className="form_col no_top"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18735].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="sign font12">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width45">
                    Name
                    <input
                      className="form_col width85"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18737].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Contact No:
                    <input
                      className="form_col width59"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18738].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    Email:
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18739].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t10">
                  <div className="fleft width20"> &nbsp; </div>
                  <div className="fleft width30">Product Name </div>
                  <div className="fleft width45">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18740].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_5t">
                  <div className="fleft width20"> &nbsp; </div>
                  <div className="fleft width30">
                    Pilot Number/Resource ID/ITFS number
                  </div>
                  <div className="fleft width45">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18741].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                <div className="over_hid pad_t15">
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        1 Company Name Change{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Company Name Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        2 Customer contact Change{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Customer contact Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        3 Billing Adress change{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Billing Adress change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        4 Site Change (PRI){' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Site Change (PRI)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        5 Activation of Service{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Activation of Service') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        6 Subscribe to service - Excel based Billing for PRI'{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Subscribe to service - Excel based Billing for PRI'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        7 Pilot Number Change (PRI){' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Pilot Number Change (PRI)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        8 DID range change/extension (PRI)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('DID range change/extension (PRI)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        9 Change in landing number (TFS/UAN/ITFS){' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Change in landing number (TFS/UAN/ITFS)') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        10 Change in Call Distribution (TFS/UAN/ITFS)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Change in Call Distribution (TFS/UAN/ITFS)'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        11 IVR configuration (TFS/UAN/ITFS)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('IVR configuration (TFS/UAN/ITFS)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        12 Subscribe to service - ILD Dial out (ACS)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Subscribe to service - ILD Dial out (ACS)'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        13 Subscribe to service - Recording (ACS)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Subscribe to service - Recording (ACS)') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        14 Subscribe to service - Operator Assistance (ACS)
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Subscribe to service - Operator Assistance (ACS)'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        15 Addition of CPE{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Addition of CPE') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        16 Deletion of CPE
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Deletion of CPE') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        17 Change in number of ports (VCS){' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Change in number of ports (VCS)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        {'18 Change in Resolution (SD<-->HD<-->FHD)(VCS)'}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Change in Resolution (SD<-->HD<-->FHD)(VCS)'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        {'19 Change in QoS( Shared<-->Dedicated) (VCS)'}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf(
                            'Change in QoS( Shared<-->Dedicated) (VCS)'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        20 Interface Change- LM provider change{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Interface Change- LM provider change') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        21 Addition of E1 (PRI){' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Addition of E1 (PRI)') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        22 Plan Change{' '}
                      </div>
                      <div className="fleft">
                        {nflCrfDataupdated[18742].value
                          .split('|')
                          .indexOf('Plan Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Company Name Change
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18744].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18745].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Customer Contact Details Change
                </p>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing telephone Number
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18747].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New telephone Number
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18748].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18749].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18750].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Billing Address Change
                </p>
                <p className="font12 margin0"> New Billing Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18753].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18754].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18755].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18756].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18757].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18758].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18759].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18760].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Site Shifting Installation Address Change
                </p>
                <p className="font12"> New Installation Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person Name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18763].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18764].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18765].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18766].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18767].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18768].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18769].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18770].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Activation of Service- PRI
                </p>
                <div className="check over_hid">
                  <div className="fleft width130px pad_t3">
                    {' '}
                    Service Required{' '}
                  </div>
                  <div className="fleft ">
                    {nflCrfDataupdated[18773].value === 'STD' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    STD
                  </div>
                  <div className="fleft pad_l10 ">
                    {nflCrfDataupdated[18773].value === 'ISD' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISD{' '}
                  </div>
                  <div className="fleft pad_l10">
                    {nflCrfDataupdated[18773].value === 'Outbound' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Outbound{' '}
                  </div>
                  <div className="fleft pad_l10">
                    {nflCrfDataupdated[18773].value === 'Inbound' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Inbound{' '}
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Subscribe to services
                </p>
                <div className="check over_hid">
                  <div className="fleft width25">
                    {' '}
                    Excel Billing (PRI)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('Excel Billing (PRI)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="fleft width25 ">
                    {' '}
                    ILD Dial Out (ACS)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('ILD Dial Out (ACS)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="fleft width22">
                    {' '}
                    Call Recording (ACS)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('Call Recording (ACS)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="fleft">
                    {' '}
                    Operator Assistance (ACS) (Specify rate)
                    {nflCrfDataupdated[18774].value
                      .split('|')
                      .indexOf('Operator Assistance (ACS) (Specify rate)') >=
                    0 ? (
                      <input
                        className="form_col width37px"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                        readOnly
                      />
                    ) : (
                      <input
                        className="form_col width37px"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width25">
                    {' '}
                    Change in number of ports(VCS)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('Change in number of ports(VCS)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="fleft width25 ">
                    {' '}
                    Change in Quality of Service(VCS)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('Change in Quality of Service(VCS)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="fleft width22">
                    {' '}
                    Change in Resolution(VCS)
                    <div className="flright pad_r10">
                      {nflCrfDataupdated[18774].value
                        .split('|')
                        .indexOf('Change in Resolution(VCS)') >= 0 ? (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col width37px"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Pilot Number Change
                </p>

                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>Existing Pilot Number</strong>
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18776].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>New Pilot Number</strong>
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18777].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>New DID Range Details</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        DID block Quantity
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18779].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        {' '}
                        DID Range Required{' '}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18780].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width10"> &nbsp; </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t5">
                  <div className="">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>&nbsp;</strong>
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                      <div className="fleft width10"> &nbsp; </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  DID range Addition / Extension
                </p>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>Pilot Number</strong>
                      </div>
                      <div className="fleft width40">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18782].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        <strong>New DID Range Details</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        Total DID block Quantity
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18784].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        {' '}
                        Current DID Range{' '}
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18785].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width10"> &nbsp; </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="over_hid pad_t5">
                  <div className="">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        DID Range Required
                      </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nflCrfDataupdated[18786].value}
                          readOnly
                        />
                      </div>
                      <div className="fleft width10"> &nbsp; </div>
                      <div className="fleft width30">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in Landing Number
                </p>
                <div className="check over_hid">
                  <div className="fleft wid120p pad_t3">New Default Number</div>
                  <div className="fleft width14 pad_r10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18788].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_l10 pad_t3"> On busy </div>
                  <div className="fleft width14">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18789].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width14 textcenter pad_l10 pad_t3">
                    {' '}
                    On No Answer{' '}
                  </div>
                  <div className="fleft width14">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18790].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft wid120p pad_t3">On Route Failure </div>
                  <div className="fleft width14 pad_r10">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18791].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_l10 pad_t3">
                    {' '}
                    On over run{' '}
                  </div>
                  <div className="fleft width14">
                    <input
                      className="form_col width90"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18792].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in Call Distribution
                </p>
                <div className="check over_hid pad_t10">
                  <div className="fleft width25">
                    <div className="changecol">
                      <p className="margin0">
                        <strong>Day of the month</strong>
                      </p>
                      <div className="check over_hid pad_t10">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Monday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Monday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Tuesday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Tuesday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Wednesday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Wednesday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Thursday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Thursday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Friday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Friday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Saturday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Saturday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="check over_hid pad_t3">
                        <div className="fleft width40 pad_l10 pad_t3">
                          Sunday{' '}
                        </div>
                        <div className="fleft width50">
                          {nflCrfDataupdated[18794].value === 'Sunday' ? (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              checked
                            />
                          ) : (
                            <input
                              className="form_col"
                              type="checkbox"
                              name=""
                              value=""
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fleft width25">
                    <div className="changecol">
                      <p className="margin0">
                        <strong>Time of the Day</strong>
                      </p>
                      <div className="check over_hid pad_t10">
                        <div className="fleft width49">
                          <div className="pad_t10">Start Time</div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[18796].value
                              )}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[19040].value
                              )}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[19042].value
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="flright width49">
                          <div className="pad_t10">End Time</div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[18797].value
                              )}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[19041].value
                              )}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={formatHHMMSS(
                                nflCrfDataupdated[19043].value
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fleft width25">
                    <div className="changecol">
                      <p className="margin0">
                        <strong>Percentage Routing</strong>
                      </p>
                      <div className="check over_hid pad_t10">
                        <div className="fleft width49">
                          <div className="pad_t10">Landing Number</div>
                          <div className="pad_t10">
                            1 &nbsp;
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[18799].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            2 &nbsp;
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19044].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            3 &nbsp;
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19046].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            4 &nbsp;
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19048].value}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="flright width40 pad_l10">
                          <div className="pad_t10">Percentage</div>
                          <div
                            style={{ paddingTop: '23px' }}
                            className="pad_t10"
                          >
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[18800].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19045].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19047].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_t10">
                            <input
                              className="form_col width70"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19049].value}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fleft width25">
                    <div className="changecol">
                      <p className="margin0">
                        <strong>IVR Routing</strong>
                      </p>
                      <div className="check over_hid">
                        <div className="fleft width40 textcenter sectionlines">
                          <div className="pad_5t">Selection</div>
                          <div className="pad_5t">1 </div>
                          <div className="pad_5t">2 </div>
                          <div className="pad_5t">3 </div>
                          <div className="pad_5t">4 </div>
                          <div className="pad_5t">5 </div>
                          <div className="pad_5t">6 </div>
                        </div>
                        <div className="flright width55">
                          <div className="pad_5t">Landing Number</div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[18802].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19050].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19051].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19052].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19053].value}
                              readOnly
                            />
                          </div>
                          <div className="pad_5t">
                            <input
                              className="form_col width80"
                              type="text"
                              name=""
                              value={nflCrfDataupdated[19054].value}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Addition of CPE
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="domestictable">
                  <div className="center_70per">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr key="0">
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr key="1">
                          <td>1</td>
                          <td>{nflCrfDataupdated[18804].value}</td>
                          <td>{nflCrfDataupdated[18805].value}</td>
                          <td>{nflCrfDataupdated[18806].value}</td>
                          <td>{nflCrfDataupdated[18807].value}</td>
                        </tr>
                        <tr key="2">
                          <td>2</td>
                          <td>{nflCrfDataupdated[19055].value}</td>
                          <td>{nflCrfDataupdated[19056].value}</td>
                          <td>{nflCrfDataupdated[19057].value}</td>
                          <td>{nflCrfDataupdated[19058].value}</td>
                        </tr>
                        <tr key="3">
                          <td>3</td>
                          <td>{nflCrfDataupdated[19059].value}</td>
                          <td>{nflCrfDataupdated[19060].value}</td>
                          <td>{nflCrfDataupdated[19061].value}</td>
                          <td>{nflCrfDataupdated[19062].value}</td>
                        </tr>
                        <tr key="4">
                          <td>4</td>
                          <td>{nflCrfDataupdated[19063].value}</td>
                          <td>{nflCrfDataupdated[19064].value}</td>
                          <td>{nflCrfDataupdated[19065].value}</td>
                          <td>{nflCrfDataupdated[19066].value}</td>
                        </tr>
                        <tr key="5">
                          <td>5</td>
                          <td>{nflCrfDataupdated[19067].value}</td>
                          <td>{nflCrfDataupdated[19068].value}</td>
                          <td>{nflCrfDataupdated[19069].value}</td>
                          <td>{nflCrfDataupdated[19070].value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Deletion of CPE
                </p>
                <div className="domestictable">
                  <div className="center_70per">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{nflCrfDataupdated[18809].value}</td>
                          <td>{nflCrfDataupdated[18810].value}</td>
                          <td>{nflCrfDataupdated[18811].value}</td>
                          <td>{nflCrfDataupdated[18812].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>{nflCrfDataupdated[19071].value}</td>
                          <td>{nflCrfDataupdated[19072].value}</td>
                          <td>{nflCrfDataupdated[19073].value}</td>
                          <td>{nflCrfDataupdated[19074].value}</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>{nflCrfDataupdated[19075].value}</td>
                          <td>{nflCrfDataupdated[19076].value}</td>
                          <td>{nflCrfDataupdated[19077].value}</td>
                          <td>{nflCrfDataupdated[19078].value}</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>{nflCrfDataupdated[19079].value}</td>
                          <td>{nflCrfDataupdated[19080].value}</td>
                          <td>{nflCrfDataupdated[19081].value}</td>
                          <td>{nflCrfDataupdated[19082].value}</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>{nflCrfDataupdated[19083].value}</td>
                          <td>{nflCrfDataupdated[19084].value}</td>
                          <td>{nflCrfDataupdated[19085].value}</td>
                          <td>{nflCrfDataupdated[19086].value}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Interface Change with Media / LM Change
                </p>
                <div className="pad_t10">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18814].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18814].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[19087].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18815].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nflCrfDataupdated[18815].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[19088].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">Existing Media</span>
                      {nflCrfDataupdated[18817].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18817].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18817].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nflCrfDataupdated[18817].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[19089].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">New Media</span>
                      {nflCrfDataupdated[18818].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18818].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nflCrfDataupdated[18818].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nflCrfDataupdated[18818].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[19090].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18820].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18821].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Addition of E1
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 wid120p">
                      Existing No of E1{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18823].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 wid120p">
                      New No of E1 Required
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18824].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Plan Change
                </p>
                <div className="check over_hid ">
                  <div className="fleft wid120p pad_t3"> Existing Plan </div>
                  <div className="fleft width30">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18826].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t10">
                  <div className="fleft wid120p pad_t3"> New Plan </div>
                  <div className="fleft width30">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nflCrfDataupdated[18827].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  COMMENTS
                </p>
                <div className="cmt_spl">
                  {' '}
                  Comments/ Special Considerations:{' '}
                  {nflCrfDataupdated[18828].value}
                </div>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="margin0">
                      <strong>Declaration</strong>
                    </p>
                    <p className="margin0">
                      I / We confirm having received, read and understood the
                      Product Terms & Conditions (provided overleaf) and the
                      General Terms & Conditions. I / We further confirm that
                      the tariff plan selected and applicable rates form part of
                      this Agreement (as defined herein) and I / We agree to
                      abide by the Applicable Law in force and also any
                      statutory amendments, or new legistlations as may be
                      enacted from time to time, in so far as they realte to the
                      services. I / We hereby declare and confirm that the above
                      information provided by us is true and correct in all
                      respects and I / We hereby undertake to be bound by the
                      same.{' '}
                    </p>
                    <p className="margin0 pad_5t">
                      Authorised Signatory's Name
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18830].value}
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_t15">
                      <div className="fleft width30">
                        <p className="margin0">Designation</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nflCrfDataupdated[18831].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Place</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nflCrfDataupdated[18832].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Date</p>
                        <p className="margin0">
                          <input
                            className="form_col width96per cmnt_input"
                            type="text"
                            name=""
                            value={nflCrfDataupdated[18833].value}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                    <p className="margin0 pad_5t">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18835].value}
                        readOnly
                      />
                    </p>
                    <p className="margin0 pad_20t">
                      Account Manager Circle Office
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col  wid120p cmnt_input"
                        type="text"
                        name=""
                        value={nflCrfDataupdated[18836].value}
                        readOnly
                      />
                    </p>
                    <div className="pad_20t">
                      <div className="fleft width50">
                        <p className="margin0">Channel Partner Name</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nflCrfDataupdated[18838].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="flright width30">
                        <p className="margin0">Channel Partner Code</p>
                        <p className="margin0">
                          <input
                            className="form_col width92per cmnt_input"
                            type="text"
                            name=""
                            value={nflCrfDataupdated[18839].value}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{
                        padding: '5px',
                        lineHeight: 'normal',
                        width: '214px',
                      }}
                      className="signate"
                    >
                      {firstCaps !== '' ? (
                        <div className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        Signature of the Customer / Authorised signatory with in
                        this box only (In case of institutinal, please affix
                        office/company seal){' '}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px',
                        height: '120px',
                        marginTop: '140px',
                        width: '216px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      className="signate"
                    >
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            acctMngSignImageData != null
                              ? 'data:' +
                                acctMngSignImageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Signature of Account Manager'}
                        />
                      ) : (
                        ''
                      )}
                      <div>Signature of the Account Manager</div>
                    </div>
                  </div>
                </div>
                <p className="textcenter">
                  <strong>
                    Vodafone Idea Limited (formerly Idea Cellular Limited) An
                    Aditya Birla Group and Vodafone Partnership Merger Co CIN
                    No. L32100GJ1996PLC030976Registered Office: Suman Tower,Plot
                    No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                  </strong>
                </p>
                <div className="signate float_img">
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </page>
        </div>
      </div>
    )
  } else {
    nflCrfContentView = CrfData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        NFL CRF Form not submitted yet.
      </div>
    )
  }
  return <div>{nflCrfContentView}</div>
}

export default CRF
